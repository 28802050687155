import {
    Module,
    getModule,
    VuexModule,
    Action,
    Mutation,
  } from "vuex-module-decorators";
  import store from "@/store";
  
  import { http } from "@/http/http";
  import sessionModule from "@/store/modules/sessions";
  
  export interface Pagination {
    Count: number;
    Page: number;
  }
  
  export interface OilLogsRequestParameters {
    from?: string;
    to?: string
    plantId?: string;
    // TODO: 別タスクで削除します。ダッシュボードでこのQuery Paramsが使用されています。
    plant_id?: string;
    pageSize?: number;
    page?: number;
    sort?: string;
  }
  
  export interface OilPriceLog {
    Id: string;
    PlantId: string;
    // TODO: APIのresponseでなぜかTotalPurchaseのデータ型がstringとなっている為、修正する。
    TotalPurchase: number;
    Price: number;
    OrderAmount: number;
    OrderDate: string; // format: YYYY-MM-DD
    CreatedAt: string; // ISO 8601 format(yyyy-MM-ddThh:mm:ssZ)
    ModifiedAt: string; // ISO 8601 format(yyyy-MM-ddThh:mm:ssZ)
  }
  
  @Module({
    dynamic: true,
    store,
    name: "oil-price-logs",
    namespaced: true,
  })
  class OilPriceLogs extends VuexModule {
    // state
    private logs: OilPriceLog[] = [];
    private logsLookup: {
      [key: string]: OilPriceLog[];
    } = {};
    private log: OilPriceLog | null = null;
    private pagination: Pagination = {
      Count: 0,
      Page: 1,
    };
  
    @Action({ rawError: true })
    public async fetchOilPriceLogs(
      queryStringParameters?: OilLogsRequestParameters
    ): Promise<void> {
      const delegateToken = sessionModule.GET_DELEGATE_TOKEN;
      const response = await http.get("/oil-price-logs", queryStringParameters, delegateToken);
      const payload: any = {
        Data: response.Data,
        Pagination: { Count: response.Count, Page: response.CurrentPage } ,
      };
      if (queryStringParameters && queryStringParameters.plantId) {
        payload.PlantId = queryStringParameters.plantId;
      }
      this.SET_LOGS(payload);
    }
  
    @Action({ rawError: true })
    public async fetchOilPriceLog(payload: { id: string }): Promise<void> {
      const delegateToken = sessionModule.GET_DELEGATE_TOKEN;
      const response = await http.get(`/oil-price-logs/${payload.id}`, null, delegateToken);
      this.SET_LOG({
        log: response,
      });
    }
  
    @Action({ rawError: true })
    public async createOilPriceLog(payload: {
      log: Partial<OilPriceLog>;
    }): Promise<void> {
      const response = await http.post("/oil-price-logs", payload.log);
    }
  
    @Action({ rawError: true })
    public async updateOilPriceLog(payload: {
      id: string;
      log: Partial<OilPriceLog>;
    }): Promise<void> {
      const response = await http.put(
        `/oil-price-logs/${payload.id}`,
        payload.log
      );
    }
  
    @Mutation
    private SET_LOGS(payload: {
      Data: OilPriceLog[];
      Pagination: Pagination;
      PlantId?: string;
    }): void {
      this.logs = payload.Data;
      if (payload.PlantId) {
        this.logsLookup[payload.PlantId] = payload.Data;
      }
      this.pagination = payload.Pagination;
    }
  
    @Mutation
    private SET_LOG(payload: { log: any }): void {
      this.log = payload.log;
    }
  
    get GET_PAGINATION() {
      return this.pagination;
    }
  
    get GET_LOGS_LOOKUP() {
      return this.logsLookup;
    }
  
    get GET_LOGS(): OilPriceLog[] {
      return this.logs;
    }
  
    get GET_LOG(): OilPriceLog | null {
      return this.log;
    }
  }
  
  export default getModule(OilPriceLogs);
  