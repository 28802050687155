/**
 * sort and return properties of inputData.
 * @param inputData // object of some kind
 * @param propertyName // Name of the property you want to sort from "inputData"
 * @returns {any} // Returns some object
 */
export const getLatestObjByAry = (
  inputData: any[],
  propertyName: string,
  // FIXME: 様々なデータ型に対応できるように実装して下さい
  dataType: "string" | "number" | "Date"
) => {
  if (!inputData) return [];

  let sortedByLatest: any[] = [];

  if (dataType === "string" || dataType === "number") {
    sortedByLatest = inputData.sort(
      (a, b) => a[propertyName] - b[propertyName]
    );
  } else if (dataType === "Date") {
    sortedByLatest = inputData
      .sort((a, b) => {
        return (
          new Date(a[propertyName]).getTime() -
          new Date(b[propertyName]).getTime()
        );
      })
      .reverse();
    // FIXME: 様々なデータ型に対応できるように実装して下さい
    // } else if (dataType === "DateTime") {
    //   return inputData.sort(
    //     (a, b) => a[propertyName].diff(b[propertyName]).milliseconds
    //   );
  } else {
    throw new Error("Could not sort");
  }

  const latestData = sortedByLatest[sortedByLatest.length - 1];
  return latestData;
};
