import {
    Module,
    getModule,
    VuexModule,
    Action,
    Mutation,
  } from 'vuex-module-decorators';
import store from '@/store';
import { http } from '@/http/http';

  
@Module({ dynamic: true, store, name: 'sessions', namespaced: true })
class Sessions extends VuexModule {
    // state
    private delegateToken: string|null = null;

    @Action({ rawError: true })
    public async getSession(delegateToken: string) {
        const sessions = await http.get('/session', null, delegateToken);
        this.SET_DELEGATE_TOKEN(sessions);
    }

    @Mutation
    public SET_DELEGATE_TOKEN(token: string): void {
        this.delegateToken = token;
    }

    @Mutation
    public CLEAR_DELEGATE_TOKEN(): void {
        this.delegateToken = null;
    }

    // getters
    get GET_DELEGATE_TOKEN(): string|null {
        return this.delegateToken;
    }
}

export default getModule(Sessions);
  