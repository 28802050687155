export function getChainedValue(obj: any, keyString: string): any {
  const splitString = keyString.split(".");
  if (!obj) {
    return "";
  }
  let l = obj;
  splitString.forEach((key) => {
    l = l[key];
    if (!l) {
      l = "";
    }
  });

  return l;
}
