import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import OwnerPlantList from '@/views/OwnerPlantListView.vue'
import PlantDetails from '@/views/PlantInformationView/PlantDetailsView.vue'
import PlantInformation from '@/views/PlantInformationView/PlantInformationView.vue'
import PlantStatistics from '@/views/PlantInformationView/PlantStatisticsView.vue'
import Login from "@/views/auth/Login.vue"
import ForceChangePassword from "@/views/auth/ChangePassword.vue"
import ForgotPassword from "@/views/auth/ForgotPassword.vue"
import Dashboard from "@/views/Dashboard.vue"
import FullScreen from "@/views/FullScreen.vue"
import Default from "@/layout/default.vue"
import { AuthFilter } from './auth'
import { Auth } from 'aws-amplify'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Default,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: Dashboard
      },
      {
        path: '/owner-plant-list',
        name: 'ownerPlantList',
        component: OwnerPlantList
      },
      {
        path: '/plant-information',
        name: 'plantInformation',
        component: PlantInformation
      },
      {
        path: '/plant-statistics/:id',
        name: 'plantStatistics',
        component: PlantStatistics
      },
      {
        path: '/plant-details/:id',
        name: 'plantDetails',
        component: PlantDetails
      },
  ]},
  {
    path: '/full-screen',
    name: 'fullScreen',
    component: FullScreen,
    meta: {
      title: 'Dashboard全画面ページ',
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: async (to, from, next) => {
      try {
       if (from.name === "changepassword" ) {
         next();
         return;
       }
 
        // if already logged in, can not open login screen
       await Auth.currentAuthenticatedUser();
       next(from);  
       return;
      } catch (error) {
       next();
       return;
      }
    },
    meta: {
      title: 'ログイン',
      isPublic: true,
    },
  },
  {
    path: '/change-password',
    name: 'changepassword',
    component: ForceChangePassword,
    meta: {
      title: 'パスワード変更',
      isPublic: true,
    }

  },
  {
    path: '/forgot-password',
    name: 'forgotpassword',
    component: ForgotPassword,
    meta: {
      title: 'パスワードを忘れ',
      isPublic: true,
    }

  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(AuthFilter);

export default router
