import {
  Module,
  getModule,
  VuexModule,
  Action,
  Mutation,
} from "vuex-module-decorators";
import store from "@/store";
import Amplify, { Auth } from "aws-amplify";
import awsExports from "@/aws-exports";
import router from "@/router";
import { http } from "@/http/http";
import sessionModule from "@/store/modules/sessions";
import { CognitoUser } from "amazon-cognito-identity-js";

Amplify.configure(awsExports);

export interface UserInfo {
  email: string;
  password: string;
}

export interface PasswordChangeInfo {
  email: string;
  password: string;
  newPassword: string;
  confirmNewPassword: string;
}

export interface ForgotPasswordInfo {
  email: string;
}
export interface ForGotPasswordSubmitInfo {
  email: string;
  code: string;
  password: string;
  confirmPassword: string;
}

@Module({ dynamic: true, store, name: "user", namespaced: true })
class User extends VuexModule {
  // state
  private errorMessage = "";
  private loggedIn = false;

  // actions
  @Action({})
  public async signIn(userInfo: UserInfo) {
    try {
      const user: CognitoUser | any = await Auth.signIn(
        userInfo.email,
        userInfo.password
      );
      // First time login
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        if (user.challengeParam.userAttributes["custom:user_group"] != "poc") {
          router.push("/login");
          const errorMessage = "ログインできません";
          this.SET_ERROR_MESSAGE(errorMessage);
          return;
        }
        router.push("/change-password");
        return;
      }
      const currentUser = await this.fetchUser(user.username);
      const delegateToken = sessionModule.GET_DELEGATE_TOKEN;
      if (!delegateToken && currentUser.Role != "owner") {
        this.SET_ERROR_MESSAGE("ログインできません。");
        router.push({ name: "login" });
        return;
      }
      this.SET_LOGGEDIN_STATUS();
      await Auth.currentSession();
      this.SET_ERROR_MESSAGE("");
      router.push("/"); // will be redirected to Dashboard
    } catch (error: any) {
      const errorMessage = await this.ErrorMessageHandling(error.code);
      this.SET_ERROR_MESSAGE(errorMessage);
    }
  }

  @Action({ rawError: true })
  public async fetchUser(id: string) {
    const delegateToken = sessionModule.GET_DELEGATE_TOKEN;
    const user = await http.get(`/users/${id}`, null, delegateToken);
    return user;
  }

  @Action({})
  public async updatePassword(userInfo: PasswordChangeInfo) {
    try {
      const user = await Auth.signIn(userInfo.email, userInfo.password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        await Auth.completeNewPassword(user, userInfo.newPassword, {});
        router.push("/login");
        return;
      }
      await Auth.changePassword(
        user,
        userInfo.password,
        userInfo.newPassword,
        {}
      );
      await Auth.currentSession();
      router.push("/");
    } catch (e: any) {
      const errorMessage = await this.ErrorMessageHandling(e.code);
      this.SET_ERROR_MESSAGE(errorMessage);
    }
  }

  @Action({})
  public async forgotPassword(userInfo: ForgotPasswordInfo) {
    try {
      await Auth.forgotPassword(userInfo.email);
    } catch (error: any) {
      const errorMessage = await this.ErrorMessageHandling(error.code);
      this.SET_ERROR_MESSAGE(errorMessage);
    }
  }

  @Action({})
  public async forgotPasswordSubmit(userInfo: ForGotPasswordSubmitInfo) {
    try {
      await Auth.forgotPasswordSubmit(
        userInfo.email,
        userInfo.code,
        userInfo.password
      );
      router.push("/login");
    } catch (error: any) {
      const errorMessage = await this.ErrorMessageHandling(error.code);
      this.SET_ERROR_MESSAGE(errorMessage);
    }
  }

  @Action({})
  public async signOut() {
    try {
      sessionModule.CLEAR_DELEGATE_TOKEN();
      await Auth.signOut();
      this.SET_LOGGEDIN_STATUS();
      router.push({ name: "login" });
    } catch (error) {
      // tslint:disable-next-line:no-console
    }
  }

  @Action({})
  public async clearError() {
    this.SET_ERROR_MESSAGE("");
  }

  @Action({})
  public async setError(error: string) {
    this.SET_ERROR_MESSAGE(error);
  }

  @Action({})
  public async checkLoggedIn() {
    try {
      const currentSession = await Auth.currentSession();
      return currentSession;
    } catch (error) {
      // tslint:disable-next-line:no-console
    }
  }

  // mutation
  @Mutation
  private SET_LOGGEDIN_STATUS(): void {
    this.loggedIn = !this.loggedIn;
  }

  @Mutation
  private SET_ERROR_MESSAGE(message: string): void {
    this.errorMessage = message;
  }

  // getters
  get GET_LOGGEDIN_STATUS() {
    return this.loggedIn;
  }

  get GET_ERROR_MESSAGE() {
    return this.errorMessage;
  }

  @Action({})
  public async ErrorMessageHandling(error: string) {
    let message = "エラーが発生されました";
    switch (error) {
      case "NotAuthorizedException":
        message = "メールアドレスまたはパスワードが間違っています。";
        break;
      case "UserNotFoundException":
        message = "入力されたメールアドレスは登録されていません。";
        break;
      case "UsernameExistsException":
        message =
          "このユーザー名は既に使用されています。別のユーザー名を入力してください。";
        break;
      case "UserNotConfirmedException":
        message = "認証コードの入力が完了していません。";
        break;
      case "LimitExceededException":
        message =
          "試行回数の制限を超えました。しばらくしてから試してください。";
        break;
      case "CodeMismatchException":
        message = "入力された認証コードが間違っていました。";
        break;
      case "ExpiredCodeException":
        message = "認証コードが期限切れになっています。";
        break;
      default:
        message = "エラーが発生しました。";
        break;
    }
    return message;
  }
}

export default getModule(User);
