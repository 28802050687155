import { DateTime } from "luxon";

type DateFormatType = {
  dateTime: Date;
  format?: string;
}

/**
 * Returns dateTime in an arbitrary format(The default format is 'yyyy.MM.dd')
 */
export const dateTimeFormatter = ({
  dateTime,
  format = 'yyyy.MM.dd',
}: DateFormatType
) => {
  return DateTime.fromJSDate(dateTime, { zone: 'Asia/Tokyo' }).toFormat(format);
}