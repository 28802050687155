<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :styles="myStyles"
  />
</template>

<script>
import { Bar } from "vue-chartjs";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "BarChart",
  components: { Bar },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
  },
  setup() {
    const myStyles = computed(() => {
      return {
        height: "100%",
        position: "relative",
      };
    });
    return {
      myStyles,
    };
  },
});
</script>
