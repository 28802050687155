
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="-7.5 -3.5 24 24"
  >
    <path
      fill="#409EFF"
      d="M5.708 4.968h1.789a1.5 1.5 0 0 1 1.378 2.09l-3.96 9.243a1.049 1.049 0 0 1-2.012-.413v-6.92L1.45 8.923A1.5 1.5 0 0 1 0 7.423V1.5A1.5 1.5 0 0 1 1.5 0h2.708a1.5 1.5 0 0 1 1.5 1.5v3.468z"
    ></path>
  </svg>
</template>

<script>
import { defineComponent } from "vue-demi";
export default defineComponent({
  name: "Thunder",
  props: {
    width: {
      type: String,
      default: "14px",
    },
    height: {
      type: String,
      default: "14px",
    },
    color: {
      type: String,
    },
  },
});
</script>