import { API } from 'aws-amplify';

class HttpClass {
  // private apiName: string = 'biomass-staging-stack';
  private apiName = 'biomass-stg-stack';
  private stagingPath = '/apps/v1';

  public async get(path: string, queryStringParameters: any = {}, delegateToken: string| null = null) {
    const getInit: any = {
      headers: {
        Application: 'poc',
      },
      response: false,
      queryStringParameters: queryStringParameters,
    };
    if(delegateToken) {
      getInit["headers"]["X-Delegate-Token"] = delegateToken;
    }
    const response = await API.get(this.apiName,`${this.stagingPath}${path}`,getInit);
    return response;
  }

  public async post(path: string, body: any) {
    const postInit: any = {
      headers: {
        Application: 'poc',
      },
      response: false,
      queryStringParameters: {},
      body,
    };
    const response = await API.post(
      this.apiName,
      `${this.stagingPath}${path}`,
      postInit,
    );
    return response;
  }

  public async put(path: string, body: any) {
    const putInit: any = {
      headers: {
        Application: 'poc',
      },
      response: false,
      queryStringParameters: {},
      body,
    };
    const response = await API.put(
      this.apiName,
      `${this.stagingPath}${path}`,
      putInit,
    );
    return response;
  }
  public async delete(path: string) {
    const putInit: any = {
      headers: {
        Application: 'poc',
      },
      response: false,
      queryStringParameters: {},
    };
    const response = await API.del(
      this.apiName,
      `${this.stagingPath}${path}`,
      putInit,
    );
    return response;
  }
}

const http = new HttpClass();
export { http };
