import {
    Module,
    getModule,
    VuexModule,
    Action,
    Mutation,
  } from "vuex-module-decorators";
  import store from "@/store";
  import { http } from "@/http/http";
import { BalanceLog, BalanceLogsRequestParameters } from "@/types/plant";
import sessionModule from "@/store/modules/sessions";
  
  
  @Module({
    dynamic: true,
    store,
    name: "balance-logs",
    namespaced: true,
  })
  class BalanceLogs extends VuexModule {
    private balanceLogs: BalanceLog[] = [];
    private balanceLogFirst: BalanceLog | null = null;
    private balanceLogsLookup: {
      [key: string]: BalanceLog[];
    } = {};
    // state  
    @Action({ rawError: true })
    public async fetchBalanceLogs(payload: {
      queryStringParameters: BalanceLogsRequestParameters;
      first?: boolean;
    }) {
      const queryStringParameters  = validate_time(payload.queryStringParameters);
      const delegateToken = sessionModule.GET_DELEGATE_TOKEN;
      const result = await http.get(
        `/balance-logs`,
        queryStringParameters, delegateToken
      );
      if (payload.first) {
        this.SET_BALANCE_LOG_FIRST({
          Result: result,
          PlantId: payload.queryStringParameters.plantId,
        });
      } else {
        this.SET_BALANCE_LOGS({
          Result: result,
          PlantId: payload.queryStringParameters.plantId,
         
        });
      }
    }
  
    // mutation
    @Mutation
    private SET_BALANCE_LOGS(payload: {
      Result: { Data: BalanceLog[] };
      PlantId: string;
    }) {
      this.balanceLogs = payload.Result.Data;
      this.balanceLogsLookup[payload.PlantId] = payload.Result.Data;
    }
  
    // mutation
    @Mutation
    private SET_BALANCE_LOG_FIRST(payload: {
      Result: { Data: BalanceLog[] };
      PlantId: string;
    }) {
      this.balanceLogFirst = payload.Result.Data[0];
    }
  
    // getter
    get GET_BALANCE_LOGS() {
      return this.balanceLogs;
    }
  
    get GET_FIRST_BALANCE_LOG() {
      return this.balanceLogFirst;
    }
  
    // getter
    get GET_BALANCE_LOGS_LOOKUP() {
      return this.balanceLogsLookup;
    }
}
  
export default getModule(BalanceLogs);
function validate_time(params: any) {
if(params.timestamp_from > params.timestamp_to)
    {
    const temp = params.timestamp_from; 
    params.timestamp_from = params.timestamp_to;
    params.timestamp_to = temp;
    return params;
    }
return params;
}
  
  