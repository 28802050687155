import { SensorCode } from "./device";

export interface TimelineAnalysisData {
    PlantAnalysis:
      {
        Key: string;
        Value: number;
      }[];
    GeneratorAnalysis: [
      {
        Imsi: string;
        BlockName: string;
        Name: string;
        Analysis: TimelineGenAnalysis[];
      }
    ];
    Ts: number;
  }

  export interface TimelineData {
    PlantId: string;
    Events: TimeEvents[];
    Alerts: TimelineAlert[];
    Analysis: TimelineGenAnalysis[];
    Generators: GeneratorData[];
    Tanks: TankData[];
    Timestamp: number;
    CreatedAt: number;
    ModifiedAt: number;
  }

  export interface GeneratorData {
    Imsi: string;
    BlockName: string;
    Name?: string;
    Events: TimelineGenEvents[];
    Alerts: TimelineAlert[],
    Analysis: TimelineGenAnalysis[];
  }

  export interface TankData {
    Imsi: string;
    BlockName: string;
    Name?: string;
    Events: TimelineGenEvents[];
    Alerts: TimelineAlert[],
  }

  export interface AnalysisMonthItem {
    month: string;
    data: TimelineAnalysisData[];
  }
  
  export interface TimelineRequestParameters {
    plantId: string;
    count?: number;
    page?: number;
    timestamp_from?: number;
    timestamp_to?: number;
    sort_direction?: 'asc' | 'desc';
  }

  export interface TimelineGenEvents {
    KindCode: string;
    Value: number;
  }

  interface TimeEvents extends TimelineGenEvents {
    Imsi: string;
  }

  interface TimelineAlert {
    Id: string;
    Name: string;
  }

  interface TimelineGenAnalysis {
    KindCode: SensorCode;
    Value: number;
  }

  export const PlantAnalysisEnum = {
   OIL_CONSUMPTION: 'oil-consumption',
   ENERGY_FEED: 'energy-feed',
  // MEMO: 下記の2つは現在不使用ですが、念の為残しています。buildAnalysisPlantChartData.tsファイルで使用されています。
   TOTAL_OUTPUT_ENERGY: 'total-output-energy',
   TOTAL_OUTPUT_WATT: 'total-output-watt',
  } as const;