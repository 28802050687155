import { Auth } from 'aws-amplify';
const awsExports = {
  Auth: {
    // フェデレーションアイデンティティのID
    identityPoolId: process.env.VUE_APP_AWS_COGNITO_IDENTIFY_POOL_ID,
    // identityPoolId: "ap-northeast-1:cdb37211-0e54-4397-84c1-4012d79d7d57",

    // リージョン
    region: process.env.VUE_APP_AWS_REGION,
    // region: "ap-northeast-1",

    // ユーザープールのID
    userPoolId: process.env.VUE_APP_AWS_COGNITO_USERPOOL_ID,
    // userPoolId: "ap-northeast-1_9J83eUcY1",

    // ユーザープールのウェブクライアントID
    userPoolWebClientId: process.env.VUE_APP_AWS_COGNITO_USERPOOL_CLIENT_ID,
    // ユーザー認証を強制
    mandatorySignIn: true,
  },
  API: {
    endpoints: [
      {
        // name: 'biomass-staging-stack',
        name: "biomass-stg-stack",
        endpoint: process.env.VUE_APP_AWS_API_ENDPOINT,
        region: "ap-northeast-1",
      },
    ],
  },
};

export default awsExports;
