import {
  Module,
  getModule,
  VuexModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import store from '@/store';
import { http } from '@/http/http';
import { DateTime } from "luxon";
import sessionModule from "@/store/modules/sessions";
import sumOilConsumption from '@/store/helpers/sumOilConsumption';
import { AnalysisMonthItem, TimelineAnalysisData, TimelineData, TimelineRequestParameters } from '@/types/timeline';
import { TankData } from './../../types/timeline';
import { getLatestObjByAry } from '@/utils/getLatestData';

export const DIGITAL_SENSOR_CODES = [
  'sensor:fire-issue',
  'sensor:running',
  'sensor:voltage-establishment',
  'sensor:any-error',
  'sensor:low-battery',
  'sensor:stop-start',
];

@Module({ dynamic: true, store, name: 'timeline', namespaced: true })
class Timeline extends VuexModule {
  // state
  private timelineData: TimelineData[] = [];
  private analysisData: TimelineAnalysisData[] = [];
  private analysisDataLookup: {
    [key: string]: TimelineAnalysisData[];
  } = {};
  private thisMonthOilConsumption: number | null = null;
  private analysisDataYear: AnalysisMonthItem[] = [];
  private timelineCurrentData: TimelineData[] = [];
  private tankData: TankData[] = [];

  // action
  @Action({})
  public async fetchTimelineData(payload: {
    params: TimelineRequestParameters;
    options?: {
      page: boolean;
    };
    current: boolean;
  }): Promise<void> {
    // default options
    const options = payload.options
      ? payload.options
      : {
        page: false,
      };

    if (!options.page) {
      if (payload.current) {
        this.EMPTY_TIMELINE_CURRENT_DATA();
      } else {
        this.EMPTY_TIMELINE_DATA();

      }
    }

    try {
      const delegateToken = sessionModule.GET_DELEGATE_TOKEN;
      const result = await http.get(`/timeline`, payload.params, delegateToken);
      if (payload.current) {
        if (options.page) {
          this.PAGE_CURRENT_TIMELINE_DATA(result);
        } else {
          this.SET_TIMELINE_CURRENT_DATA(result);
        }
      } else {
        if (options.page) {
          this.PAGE_TIMELINE_DATA(result);
        } else {
          this.SET_TIMELINE_DATA(result);
        }
      }
      this.SET_TANK_DATA(result);
      
      return result;
    } catch (error) {
      // tslint:disable-next-line:no-console
    }
  }

  @Action({})
  public async fetchTimelineAnalysisData(payload: {
    params: TimelineRequestParameters;
    options?: {
      page: boolean;
    };
    current: boolean;
  }): Promise<void> {
    // default options
    const options = payload.options
      ? payload.options
      : {
        page: false,
      };

    if (!options.page) {
      this.EMPTY_ANALYSIS_DATA();
    }
    try {
      const delegateToken = sessionModule.GET_DELEGATE_TOKEN;
      const result = await http.get(`/timeline/analyses`, payload.params, delegateToken);
      if (options.page) {
        this.PAGE_ANALYSIS_DATA(result);
      } else {
        this.SET_ANALYSIS_DATA({
          analysisData: result,
          plantId: payload.params.plantId,
        });
      }
    } catch (error) {
      // tslint:disable-next-line:no-console
    }
  }

  @Action({})
  public async fetchThisMonthOilConsumption(plantId: string): Promise<void> {
    // default options

    this.EMPTY_OIL_CONSUMPTION();
    try {
      const startDate = DateTime.local().startOf('month')
      const params = {
        plantId: plantId,
        timestamp_from: startDate.toSeconds(),
      };
      const delegateToken = sessionModule.GET_DELEGATE_TOKEN;
      const result: TimelineAnalysisData[] = await http.get(
        `/timeline/analyses`,
        params,
        delegateToken
      );
      const sum = sumOilConsumption(result);
      this.SET_OIL_CONSUMPTION(sum);
    } catch (error) {
      // tslint:disable-next-line:no-console
    }
  }

  @Action({ rawError: true })
  public async fetchTimelineAnalysisYearData(payload: {
    plantId: string;
    startDate: DateTime;
  }): Promise<void> {
    this.EMPTY_ANALYSIS_YEAR_DATA();
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
    const promises = months.map((month) => {
      const monthStart = payload.startDate.plus({ months: -1 }); //TODO: because changed from payload.startDate.clone().add(1, "month") so need to check result 
      const monthEnd = monthStart.plus({ months: 1 });
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<void>(async (resolve, reject): Promise<void> => {
        try {
          const params = {
            plantId: payload.plantId,
            timestamp_from: monthStart.toSeconds(),
            timestamp_to: monthEnd.toSeconds(),
          };
          const delegateToken = sessionModule.GET_DELEGATE_TOKEN;
          const result = await http.get(`/timeline/analyses`, params, delegateToken);
          this.SET_ANALYSIS_YEAR_DATA({
            monthData: { month: monthStart.toLocaleString(), data: result },
            index: month - 1,
          });

          resolve();
        } catch (e) {
          this.SET_ANALYSIS_YEAR_DATA({
            monthData: { month: monthStart.toLocaleString(), data: [] },
            index: month - 1,
          });
          resolve();
        }
      });
    });
    await Promise.all(promises);
  }

  @Action
  public emptyAnalysisDataLookup() {
    this.EMPTY_ANALYSIS_DATA_LOOKUP();
  }

  // mutation
  @Mutation
  private SET_TIMELINE_DATA(timelineData: TimelineData[]) {
    this.timelineData = timelineData;
  }

  @Mutation
  private SET_ANALYSIS_DATA(payload: {
    analysisData: TimelineAnalysisData[];
    plantId: string;
  }) {
    this.analysisData = payload.analysisData;
    this.analysisDataLookup[payload.plantId] = payload.analysisData;
  }

  @Mutation
  private SET_OIL_CONSUMPTION(sum: number) {
    this.thisMonthOilConsumption = sum;
  }

  @Mutation
  private SET_ANALYSIS_YEAR_DATA(payload: {
    monthData: AnalysisMonthItem;
    index: number;
  }) {
    this.analysisDataYear[payload.index] = payload.monthData;
  }

  // mutation
  @Mutation
  private SET_TIMELINE_CURRENT_DATA(timelineData: TimelineData[]) {
    this.timelineCurrentData = timelineData;
  }

  @Mutation
  private EMPTY_TIMELINE_DATA() {
    this.timelineData = [];
  }

  @Mutation
  private EMPTY_ANALYSIS_DATA_LOOKUP() {
    this.analysisDataLookup = {};
  }

  @Mutation
  private EMPTY_ANALYSIS_YEAR_DATA() {
    this.analysisDataYear = [];
  }

  @Mutation
  private EMPTY_ANALYSIS_DATA() {
    this.analysisData = [];
  }

  @Mutation
  private EMPTY_OIL_CONSUMPTION() {
    this.thisMonthOilConsumption = null;
  }

  @Mutation
  private EMPTY_TIMELINE_CURRENT_DATA() {
    this.timelineData = [];
  }

  // mutation
  @Mutation
  private PAGE_TIMELINE_DATA(timelineData: TimelineData[]) {
    this.timelineData = [...this.timelineData, ...timelineData];
  }

  // mutation
  @Mutation
  private PAGE_CURRENT_TIMELINE_DATA(timelineData: TimelineData[]) {
    this.timelineCurrentData = [...this.timelineCurrentData, ...timelineData];
  }

  @Mutation
  private PAGE_ANALYSIS_DATA(analysisData: TimelineAnalysisData[]) {
    this.analysisData = [...this.analysisData, ...analysisData];
  }

  @Mutation
  private SET_TANK_DATA(timelineData: TimelineData[]) {
    if (timelineData.length > 0) {
      const latestTimelineData = getLatestObjByAry(timelineData, "Timestamp", "number");
      this.tankData = latestTimelineData.Tanks;
    }
  }

  // getter
  get GET_TIMELINE_DATA() {
    return this.timelineData;
  }

  get GET_ANALYSIS_DATA() {
    return this.analysisData;
  }

  get GET_ANALYSIS_DATA_LOOKUP() {
    return this.analysisDataLookup;
  }

  get GET_OIL_CONSUMPTION() {
    return this.thisMonthOilConsumption;
  }

  get GET_ANALYSIS_YEAR_DATA() {
    return this.analysisDataYear;
  }

  get GET_TIMELINE_DATA_CURRENT() {
    return this.timelineCurrentData;
  }

  get GET_TANK_DATA() {
    return this.tankData;
  }
}

export default getModule(Timeline);
