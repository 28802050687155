import { AlertResponseHistory, SensorLogPort } from '@/types/alert';
import { IPagination } from '@/types/pagination';
import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';
import { VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { http } from '@/http/http';
import { AlertLogsRequestParameters } from '@/types/alert';
import sessionModule from "@/store/modules/sessions";

@Module({ dynamic: true, store, name: 'alertLogs', namespaced: true  })
class AlertLogs extends VuexModule {
  // state
  private singleAlertLogPorts: Partial<SensorLogPort> = {};
  private alertLogPorts: SensorLogPort[] = [];
  private pagination: IPagination = {
    Count: 0,
    Page: 1,
  };

  // action
  @Action({ rawError: true })
  public async fetchAlertLogs(params: AlertLogsRequestParameters) {
    const delegateToken = sessionModule.GET_DELEGATE_TOKEN;
    const result = await http.get('/alert-logs', params, delegateToken);
    this.SET_ALERT_LOG_PORTS({ AlertLogs: result.Data, Pagination: { Count: result.Count, Page: result.CurrentPage } });
  }

  @Action({ rawError: true })
  public async fetchAlertLog(fetchAlertLog: string) {
    const delegateToken = sessionModule.GET_DELEGATE_TOKEN;
    const result = await http.get(`/alert-logs/${fetchAlertLog}`,null,delegateToken);
    this.SET_ALERT_LOG_PORT(result);
    return result;
  }

  @Action({ rawError: true })
  public async fetchAlertLogsWithDevices(fetchAlertLog: string) {
    const alertLogPorts: SensorLogPort = await this.fetchAlertLog(fetchAlertLog);
    const sensorIMSI = alertLogPorts.AlertLog.SensorLogImsi;
    this.context.dispatch('devices/fetchDevicesByIMSI', sensorIMSI, {root: true});
  }

  // mutation
  @Mutation
  private SET_ALERT_LOG_PORTS( payload: {
    AlertLogs: SensorLogPort[];
    Pagination: IPagination;
  }): void {
    this.alertLogPorts = payload.AlertLogs;
    this.pagination = payload.Pagination;
  }

  @Mutation
  private SET_ALERT_LOG_PORT(alertLog: Partial<SensorLogPort>): void {
    this.singleAlertLogPorts = alertLog;
  }

  // This method is called from another module
  @Mutation
  private SET_POSTED_RESPONSE_FOR_ALERT_LOG_PORT(response: AlertResponseHistory): void {
    if (response.NewStatus !== null) {
      this.singleAlertLogPorts.Status = response.NewStatus;
    }
    if (response.NewDueDate !== null) {
      this.singleAlertLogPorts.DueDate = response.NewDueDate;
    }
  }

  // getters
  get GET_ALERT_LOG_PORTS(): SensorLogPort[] {
    return this.alertLogPorts;
  }

  get GET_ALERT_LOG_PORT_LOOKUP(): Partial<SensorLogPort> {
    return this.singleAlertLogPorts;
  }

  get GET_PAGINATION(): IPagination {
    return this.pagination;
  }
}

export default getModule(AlertLogs);

