import { BalanceLogChartItem, BalanceLog } from "@/types/plant";
import { DateTime } from "luxon";

const reducePlantsExpenditureYear = (
  data: BalanceLog[],
  startDateUnix: number
): BalanceLogChartItem[] => {
  let time = DateTime.fromSeconds(startDateUnix);
  const aggregated: BalanceLogChartItem[] = [];
  for (let i = 1; i <= 12; i++) {
    const filteredForMonth = data.filter((item) => {
      return DateTime.fromSeconds(item.Timestamp).startOf('month').equals(time.startOf('month'));
    });
    const reduced = filteredForMonth.reduce(
      (prevBalance: BalanceLogChartItem, currentBalance: BalanceLog): BalanceLogChartItem => {
        return {
          Month: prevBalance.Month,
          Expenditures: prevBalance.Expenditures
            ? prevBalance.Expenditures + currentBalance.Payload.Expenditures
            : currentBalance.Payload.Expenditures,
          Profit: prevBalance.Profit
            ? prevBalance.Profit + currentBalance.Payload.Profit
            : currentBalance.Payload.Profit,
            Sales: prevBalance.Sales ? prevBalance.Sales + currentBalance.Payload.SalePrice : currentBalance.Payload.SalePrice
        };
      },
      {
        Month: time.toSeconds(),
        Expenditures: null,
        Profit: null,
        Sales: null,
      }
    );
    aggregated.push(reduced);

    time = time.plus({ months: 1 });
  }

  return aggregated;
};
export default reducePlantsExpenditureYear;
