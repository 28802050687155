export interface Device {
  Imsi: string;
  Name: string;
  DeviceCode: string;
  PlantId: string;
  Payload: {
    MacAddress: string;
    TargetModules: TargetModule[];
    LocalId: number;
  };
  CreatedAt: Date;
  ModifiedAt: Date;
}

export interface TargetModule {
  Block: string;
  DeviceKindCode: DeviceKindCode;
  TargetModuleCode: TargetModuleCode;
  IsEnabled: boolean;
  IoPortMapping: IoPortMapping[];
  Name?: string;
  isRunning: boolean | null
}

export interface IoPortMapping {
  Index: number;
  SensorCode: SensorCode;
  Alert: true;
  AlertRuleId: string;
  IsEnabled: true;
  IoPortCode: IoPortCode;
}

export type SensorCode = 
  "sensor:fire-issue" |
  "sensor:running" |
  "sensor:voltage-establishment" |
  "sensor:any-error" |
  "sensor:low-battery" |
  "sensor:output-ampere" |
  "sensor:output-voltage" |
  "sensor:output-watt" |
  "sensor:output-frequency" |
  "sensor:engine-oil-pressure" |
  "sensor:battery-voltage" |
  "sensor:bearing-temperature" |
  "sensor:exhaust-gas-temperature" |
  "sensor:engine-oil-temperature" |
  "sensor:engine-water-temperature" |
  "sensor:stop-start" |
  "sensor:emergency-stop" |
  "sensor:system-error" |
  "sensor:plant-tank-lowlevel-danger" |
  "sensor:plant-tank-lowlevel" |
  "sensor:flowmeter-feed" |
  "sensor:flowmeter-back" |
  "sensor:indoor-temperature" |
  "sensor:outdoor-temperature";

export const SensorCodes = {
  FIRE_ISSUE: 'sensor:fire-issue',
  RUNNING: 'sensor:running',
  VOLTAGE_ESTABLISHMENT: 'sensor:voltage-establishment',
  ANY_ERROR: 'sensor:any-error',
  LOW_BATTERY: 'sensor:low-battery',
  OUTPUT_AMPERE: 'sensor:output-ampere',
  OUTPUT_VOLTAGE: 'sensor:output-voltage',
  OUTPUT_WATT: 'sensor:output-watt',
  OUTPUT_FREQUENCY: 'sensor:output-frequency',
  ENGINE_OIL_PRESSURE: 'sensor:engine-oil-pressure',
  BATTERY_VOLTAGE: 'sensor:battery-voltage',
  BEARING_TEMPERATURE: 'sensor:bearing-temperature',
  EXHAUST_GAS_TEMPERATURE: 'sensor:exhaust-gas-temperature',
  ENGINE_OIL_TEMPERATURE: 'sensor:engine-oil-temperature',
  ENGINE_WATER_TEMPERATURE: 'sensor:engine-water-temperature',
  STOP_START: 'sensor:stop-start',
  EMERGENCY_STOP: 'sensor:emergency-stop',
  SYSTEM_ERROR: 'sensor:system-error',
  PLANT_TANK_LOWLEVEL_DANGER: 'sensor:plant-tank-lowlevel-danger',
  PLANT_TANK_LOWLEVEL: 'sensor:plant-tank-lowlevel',
  FLOWMETER_FEED: 'sensor:flowmeter-feed',
  FLOWMETER_BACK: 'sensor:flowmeter-back',
  INDOOR_TEMPERATURE: 'sensor:indoor-temperature',
  OUTDOOR_TEMPERATURE: 'sensor:outdoor-temperature',
} as const;

enum IoPortCode {
  inputDigital = "ioport:input-digital",
  inputAnalog = "ioport:input-analog",
  inputThermocouple = "ioport:input-thermocouple",
}

enum DeviceKindCode {
  plant = "devicekind:plant",
  tank = "devicekind:tank",
  generator = "devicekind:generator",
}

enum TargetModuleCode {
  plant = "targetmodule:plant-v1",
  tank = "targetmodule:tank-v1",
  generator = "targetmodule:airman-gen-v1",
}