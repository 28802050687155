import { TimelineAnalysisData } from "@/types/timeline";


const sumOilConsumption = (data: TimelineAnalysisData[]): number => {
  return data.reduce((a, b) => {
    const consumptionItem = b.PlantAnalysis.find(
      (ana: { Key: string; }) => ana.Key === 'oil-consumption',
    );
    if (!consumptionItem) {
      return a;
    }
    return a + consumptionItem.Value;
  }, 0);
};

export default sumOilConsumption;
