import {
  Module,
  getModule,
  VuexModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import store from '@/store';
import { http } from '@/http/http';
import { IPagination } from '@/types/pagination';
import { 
  MaintenanceSchedule,
  MaintenanceSchedulesRequestParameters
} from '@/types/maintenanceSchedule';
import sessionModule from "@/store/modules/sessions";

@Module({ dynamic: true, store, name: 'maintenanceSchedules', namespaced: true })
class MaintenanceSchedules extends VuexModule {
  // state
  private maintenanceSchedules: MaintenanceSchedule[] = [];
  private pagination: IPagination = {
    Count: 0,
    Page: 1,
  };

  // action
  @Action({ rawError: true })
  public async fetchMaintenanceSchedules(params: MaintenanceSchedulesRequestParameters) {
    const delegateToken = sessionModule.GET_DELEGATE_TOKEN;
    const result = await http.get('/maintenance-schedules', params, delegateToken);
    this.SET_MAINTENANCE_SCHEDULES({ MaintenanceSchedules: result.Data, Pagination: { Count: result.Count, Page: result.CurrentPage } });
  }

  // mutations
  @Mutation
  private SET_MAINTENANCE_SCHEDULES(payload: {
    Pagination: IPagination;
    MaintenanceSchedules: MaintenanceSchedule[];
  }): void {
    this.maintenanceSchedules = payload.MaintenanceSchedules;
    this.pagination = payload.Pagination;
  }

  // getters
  get GET_MAINTENANCE_SCHEDULES(): MaintenanceSchedule[] {
    return this.maintenanceSchedules;
  }

  get GET_PAGINATION(): IPagination {
    return this.pagination;
  }
}

export default getModule(MaintenanceSchedules);