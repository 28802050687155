/**
 * Returns the 'status' of alert_response_histories converted to Japanese.
 */
export const alertStatusConstant = (
  status: string
): string | undefined => {
  switch (status) {
    case 'pending':
      return '未対応';
    case 'processing':
      return '対応中';
    case 'complete':
      return '対応済';
  }
};