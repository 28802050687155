import { ElMessage } from "element-plus";
import {
  Module,
  getModule,
  VuexModule,
  Action,
  Mutation,
} from "vuex-module-decorators";
import store from "@/store";
import { http } from "@/http/http";
import { IPagination } from "@/types/pagination";
import {
  SalesLogsRequestParameters,
  SalesPriceLog,
} from "@/types/salesPriceLog";

@Module({
  dynamic: true,
  store,
  name: "sales-price-logs",
  namespaced: true,
})
class SalesPriceLogs extends VuexModule {
  // state
  private salesPriceLogs: SalesPriceLog[] = [];
  private pagination: IPagination = {
    Count: 0,
    Page: 1,
  };

  @Action({ rawError: true })
  public async fetchSalesPriceLogs(
    queryStringParameters?: SalesLogsRequestParameters
  ): Promise<void> {
    const response = await http.get("/sales-price-logs", queryStringParameters);
    const payload = {
      Data: response.Data,
      Pagination: { Count: response.Count, Page: response.CurrentPage },
    };
    this.SET_SALES_PRICE_LOGS(payload);
  }

  @Mutation
  private SET_SALES_PRICE_LOGS(payload: {
    Data: SalesPriceLog[];
    Pagination: IPagination;
  }): void {
    this.salesPriceLogs = payload.Data;
    this.pagination = payload.Pagination;
  }

  get GET_SALES_PRICE_LOGS(): SalesPriceLog[] {
    return this.salesPriceLogs;
  }

  get GET_PAGINATION() {
    return this.pagination;
  }
}
export default getModule(SalesPriceLogs);
