import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import "@/plugins/formValidationConfig";
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
  } from "chart.js";

Amplify.configure(awsExports);
const app = createApp(App)
// For loading all el-icons
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
  );

app.use(ElementPlus)
app.use(store)
app.use(router)
app.mount('#app');

