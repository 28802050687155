//<reference path="path/types.d.ts" />
import {
  Module,
  getModule,
  VuexModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import store from '@/store';
import { http } from '@/http/http';
import { Plant, PlantsRequestParameters, Prefectures } from '@/types/plant';
import { IPagination } from '@/types/pagination';
import sessionModule from "@/store/modules/sessions"
@Module({ dynamic: true, store, name: 'plants', namespaced: true })
class Plants extends VuexModule {
// state
private plants: Plant[] = [];
private pagination: IPagination = {
  Count: 0,
  Page: 1,
};
private plant: Plant | null = null;
private prefectures: Prefectures[] = require(`@/utils/Prefectures.json`);

  @Action({ rawError: true })
  public async fetchPlants(queryStringParameters?: PlantsRequestParameters) {
    const delegateToken = sessionModule.GET_DELEGATE_TOKEN;
    const result = await http.get('/plants', queryStringParameters, delegateToken);
    this.SET_PLANTS({ Plants: result.Data, Pagination: { Count: result.Count, Page: result.CurrentPage } });
  }

  @Action({ rawError: true })
  public async fetchPlant(id: string) {
    const delegateToken = sessionModule.GET_DELEGATE_TOKEN;
    const plant = await http.get(`/plants/${id}`, null, delegateToken);
    this.SET_PLANT(plant);
  }

@Action({ rawError: true })
public async createPlant(body: any) {
  const result = await http.post('/plants', body);
  this.SET_PLANT(result);
}

@Action({ rawError: true })
public async updatePlant(params: { id: string; body: any }) {
  const result = await http.put(`/plants/${params.id}`, params.body);
  this.SET_PLANT(result);
}

// mutation
@Mutation
private SET_PLANTS(payload: { Plants: Plant[]; Pagination: IPagination }) {
  this.plants = payload.Plants;
  this.pagination = payload.Pagination;
}

@Mutation
private SET_PLANT(plantData: Plant) {
  this.plant = plantData;
}

get GET_PLANTS() {
  return this.plants;
}

get GET_PAGINATION() {
  return this.pagination;
}

get GET_PLANT() {
  return this.plant;
}
}

export default getModule(Plants);
