import * as yup from "yup";

export const loginSchema = yup.object({
  email: yup
    .string()
    .matches(/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/, "メールアドレスを正しく入力してください。")
    .required("メールを入力してください。")
    .email("メールアドレスの形式で入力してください。")
    .label("Email"),
  password: yup
    .string()
    .required("パスワードを入力してください。")
    .label("Password"),
});

export const changePasswordSchema = yup.object({
  email: yup
    .string()
    .matches(/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/, "メールアドレスを正しく入力してください。")
    .required("メールを入力してください。")
    .email("メールアドレスの形式で入力してください。")
    .label("Email"),
  password: yup
    .string()
    .required("パスワードを入力してください。")
    .label("Password"),
  newPassword: yup
    .string()
    .required("新しいパスワードを入力してください。")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "8文字以上・大文字・小文字・記号・数字を入力してください"
    )
    .label("New Password"),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "パスワードの一致が必要です"),
});

export const emailSchema = yup.object({
  email: yup
    .string()
    .matches(/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/, "メールアドレスを正しく入力してください。")
    .required("メールを入力してください。")
    .email("メールアドレスの形式で入力してください。")
    .label("Email"),
});

export const forgotPasswordSubmit = yup.object({
  code: yup
    .string()
    .required("コードを入力してください。")
    .label("Confirmation Code"),
  email: yup
    .string()
    .matches(/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/, "メールアドレスを正しく入力してください。")
    .required("メールを入力してください。")
    .email("メールアドレスの形式で入力してください。")
    .label("Email"),
  password: yup
    .string()
    .required("新しいパスワードを入力してください。")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "8文字以上・大文字・小文字・記号・数字を入力してください"
    )
    .label("New Password"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "パスワードの一致が必要です"),
});