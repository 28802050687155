import { PlantAnalysisEnum } from "./../../types/timeline";
import { TimelineAnalysisData } from "@/types/timeline";
import { DateTime } from "luxon";

export type FullScreenChartDataType = {
  energyFeed: (number | null)[];
  oilConsumption: (number | null)[];
};

export type BuiltAnalysisPlantDataResult = {
  PlantAnalysis: {
    Key: string;
    Value: number | null;
  }[];
  CreatedAt: DateTime;
};

const dataKeys = [
  PlantAnalysisEnum.ENERGY_FEED,
  PlantAnalysisEnum.OIL_CONSUMPTION,
];

const buildAnalysisPlantChartData = (
  timePeriod: string,
  data: TimelineAnalysisData[],
  startUnixSec: number,
  endUnixSec: number,
  isRetrieveDaysBefore?: boolean
) => {
  const result: BuiltAnalysisPlantDataResult[] = [];
  const from = DateTime.fromSeconds(startUnixSec);
  const to = DateTime.fromSeconds(endUnixSec);
  if (!data || data.length <= 0) {
    // Create data for graph non-display when there are no data.
    const noValueArray: any[] = [];
    const noValueResult: BuiltAnalysisPlantDataResult[] = [];

    const makeNoValueResult = (dateTime: DateTime) => {
      const zeroValuePlantAnalysis = dataKeys.map((key) => {
        return {
          Key: key,
          Value: null,
        };
      });
      noValueResult.push({
        PlantAnalysis: zeroValuePlantAnalysis,
        CreatedAt: dateTime,
      });
    };

    switch (timePeriod) {
      case "day":
        {
          for (let i = 0; i <= 24; i++) {
            const eachHourOfDay = from.plus({ hour: i });
            noValueArray.push({ x: eachHourOfDay.toJSDate(), y: null });
            makeNoValueResult(eachHourOfDay);
          }
        }
        break;

      case "month": {
        const daysInMonth = from.daysInMonth;
        for (let i = 0; i < daysInMonth; i++) {
          const eachDayOfMonth = from.plus({ day: i });
          noValueArray.push({ x: eachDayOfMonth.toJSDate(), y: null });
          makeNoValueResult(eachDayOfMonth);
        }
      }
    }

    const energyFeedValues = noValueArray;
    // NOTE: 現在は不使用ですが、念の為残しています。もし完全に不要となれば下記の処理と、関連する処理も併せて削除してください。
    const wattageValues = noValueArray;
    const energyValues = noValueArray;
    const oilConsumptionValues = noValueArray;
    return [
      energyFeedValues,
      wattageValues,
      oilConsumptionValues,
      energyValues,
      noValueResult,
    ];
  }

  switch (timePeriod) {
    case "day": {
      let dateTime = from.startOf("hour");
      for (let i = 0; i < 24; i++) {
        /** Get data for one hour of the day in an array */
        const dataCurrentHour = data.find((ana) => {
          const anaTime = DateTime.fromSeconds(Number(ana.Ts));
          return anaTime.hasSame(dateTime, "hour");
        });

        const dailyAnalysesData = dataKeys.map((key) => {
          const currentData = dataCurrentHour?.PlantAnalysis.find(
            (analysis) => analysis.Key === key
          );
          let value = null;
          if(currentData) {
            value = currentData.Value || 0;
          }

          return {
            Key: key,
            Value: value,
          };
        });

        result.push({
          PlantAnalysis: dailyAnalysesData,
          CreatedAt: dateTime,
        });
        dateTime = dateTime.plus({ hours: 1 });
      }
      break;
    }

    case "month": {
      /** The full-screen graph for the current month shows data for the 31 days prior to today.
       * The graph for the previous month shows data from the first to the last day of the month. */
      const DAYS_AGO = 31;
      /** Logic to set the number of days to display graphs on all screens. */
      const days = isRetrieveDaysBefore
        ? DAYS_AGO
        : to.minus({ months: 1 }).daysInMonth;
      let dateTime = from;

      for (let i = 0; i < days; i++) {
        /** Get data for one day of the month in an array */
        const dataCurrentDay = data.filter((ana) => {
          const anaTime = DateTime.fromSeconds(Number(ana.Ts));
          const isSame = anaTime.hasSame(dateTime, "day");
          return isSame;
        });

        /** Get data for each day of the month (If the selected month has 30 days => The number of items in the array is 30) */
        const monthlyAnalysesData = dataKeys.map((key) => {
          const values = getValueByKey(dataCurrentDay, key);
          const total = values.reduce((a, b) => {
            return a + b;
          }, 0);

          return {
            Key: key,
            Value: total > 0 ? total : 0,
          };
        });

        result.push({
          PlantAnalysis: monthlyAnalysesData,
          CreatedAt: dateTime,
        });

        dateTime = dateTime.plus({ days: 1 });
      }

      break;
    }
  }

  // NOTE: 現在は不使用ですが、念の為残しています。もし完全に不要となれば下記の処理と、関連する処理も併せて削除してください。
  const wattageValues = result.map((t) => {
    let value = null;
    const wattData = t.PlantAnalysis.find(
      (a: { Key: string }) => a.Key === PlantAnalysisEnum.TOTAL_OUTPUT_WATT
    );
    if(wattData) {
      value = wattData.Value || 0;
    }

    return {
      x: new Date(Math.round(t.CreatedAt.toMillis())),
      y: value,
    };
  });

  const energyValues = result.map((t) => {
    let value = null;
    const energyData = t.PlantAnalysis.find(
      (a: { Key: string }) => a.Key === PlantAnalysisEnum.TOTAL_OUTPUT_ENERGY
    );
    if(energyData) {
      value = energyData.Value || 0;
    }
    return {
      x: new Date(Math.round(t.CreatedAt.toMillis())),
      y: value,
    };
  });

  const energyFeedValues = result.map((t) => {
    let value = null;
    const energyFeedData = t.PlantAnalysis.find(
      (a: { Key: string }) => a.Key === PlantAnalysisEnum.ENERGY_FEED
    );
    if(energyFeedData) {
      value = energyFeedData.Value || 0;
    }
    return {
      x: new Date(Math.round(t.CreatedAt.toMillis())),
      y: value,
    };
  });

  const oilConsumptionValues = result.map((t) => {
    let value = null;
    const oilConsumptionData = t.PlantAnalysis.find(
      (a: { Key: string }) => a.Key === PlantAnalysisEnum.OIL_CONSUMPTION
    );
    if(oilConsumptionData) {
      value = oilConsumptionData.Value || 0;
    }
    return {
      x: new Date(Math.round(t.CreatedAt.toMillis())),
      y: value,
    };
  });

  return [
    energyFeedValues,
    wattageValues,
    oilConsumptionValues,
    energyValues,
    result,
  ];
};

export default buildAnalysisPlantChartData;

/**
 * To use the value for the graph, the value is taken from the TimelineAnalysisData based on the argument key and stored in an array.
 * @param dataCurrentDay TimelineAnalysisData for "1 hour" or "1 day"
 * @param key ex. "energy-feed"
 * @returns {number[]} ex.[10, 78, 46, ...]
 */
const getValueByKey = (analysisData: TimelineAnalysisData[], key: string) => {
  return analysisData.map((item) => {
    const searchedAnalysis = item.PlantAnalysis.find(
      (analysis) => analysis.Key === key
    );
    return searchedAnalysis?.Value || 0;
  });
};
