import Amplify, { Auth } from "aws-amplify";
import awsExports from "@/aws-exports";

Amplify.configure(awsExports);

const AuthFilter = async (to: any, from: any, next: any) => {
  const token = to.query ? to.query.token : null;
  if(token || token != undefined) {
    next({ name: "dashboard", params: {token: token} });
    return;
  }
  try {
    await Auth.currentAuthenticatedUser();
    next();
    return;
  } catch (error) {
    if (
      to.matched.some(
        (record: { meta: { isPublic: any } }) => !record.meta.isPublic
      )
    ) {
      const token = to.params ? to.params.token : null;
      if(token) {
        next({ name: "login", params: {token: token}});
        return;
      }
      next({ path: "/login", query: { redirect: to.fullPath } });
      return;
    }
    next();
    return;
  }
};

export { AuthFilter };
